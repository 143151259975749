%container-style {
  background-color: var(--bg);
  line-height: var(--line-height);
}

[data-theme] {
  font-size: var(--font-size);
  font-family: var(--font-family);
  color: var(--fg);

  button,
  input {
    font: inherit;
    line-height: inherit;
  }

  .button {
    user-select: none;
  }

  .list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .form-control {
    font-family: var(--font-family);
    padding: 0.625rem 1rem;
    background-color: var(--control-bg);
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
  }
}
