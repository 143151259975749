.grid-layout {
  --lk-col-count: 1;
  --lk-row-count: 1;
  display: grid;
  grid-template-columns: repeat(var(--lk-col-count), minmax(0, 1fr));
  grid-auto-rows: minmax(0, 1fr);
  grid-gap: var(--grid-gap);
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  padding: var(--grid-gap);

  &[data-pagination='true'] {
    padding-bottom: calc(var(--grid-gap) * 2);
  }
}
