.track-muted-indicator-camera,
.track-muted-indicator-microphone {
  position: relative;
  width: var(--indicator-size, 1rem);
  height: var(--indicator-size, 1rem);
  margin-inline-end: 0.25rem;
  // background-repeat: no-repeat;
  // background-image: var(--bg);
  transition: opacity 0.25s ease-in-out;
}

.track-muted-indicator-camera {
  // --bg: var(--icon-camera);

  &[data-muted='true'] {
    opacity: 0.5;
    // --bg: var(--icon-camera-disabled);
  }
}
.track-muted-indicator-microphone {
  --bg: var(--icon-mic);

  &[data-muted='true'] {
    opacity: 0.5;
    // --bg: var(--icon-mic-disabled);
  }
}
