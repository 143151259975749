.focus-layout-wrapper,
.grid-layout-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: calc(100% - var(--control-bar-height));
}

.grid-layout-wrapper {
  flex-direction: column;
  align-items: center;
}

.focus-layout-wrapper {
  align-items: stretch;
}

.video-conference {
  position: relative;
  display: flex;
  align-items: stretch;
  height: 100%;
}

.video-conference-inner {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}

.settings-menu-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--lk-bg);
  padding: 1rem;
  border-radius: var(--lk-border-radius);

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  background-color: var(--lk-bg);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  min-width: 50vw;
  min-height: 50vh;
}
