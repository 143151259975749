@import '../preflight';

.prejoin {
  @extend %container-style;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  margin-inline: auto;
  background-color: var(--bg);
  width: min(100%, 480px);
  align-items: stretch;

  .video-container {
    position: relative;
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 10;
    background-color: black;
    border-radius: var(--lk-border-radius);
    overflow: hidden;
    video,
    .camera-off-note {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    video[data-facing-mode='user'] {
      transform: rotateY(180deg);
    }

    .camera-off-note {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      aspect-ratio: 16 / 10;
      background-color: black;

      display: grid;
      place-items: center;
      & > * {
        height: 70%;
        max-width: 100%;
      }
    }
  }

  .audio-container {
    display: none;
    audio {
      width: 100%;
      height: auto;
    }
  }

  .button-group-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 1rem;

    > .button-group {
      width: 50%;

      > .button {
        justify-content: left;
      }

      > .button:first-child {
        width: 100%;
      }
    }
  }
  @media (max-width: 400px) {
    .button-group-container {
      flex-wrap: wrap;
      > .button-group {
        width: 100%;
      }
    }
  }

  .username-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 100%;
  }

  .join-button {
    --control-fg: var(--accent-fg);
    --control-bg: var(--accent-bg);
    --control-hover-bg: var(--accent2);
    --control-active-bg: var(--accent3);
    --control-active-hover-bg: var(--accent4);
    background-color: var(--control-bg);
    &:hover {
      background-color: var(--control-hover-bg);
    }
  }
}
