@import '../preflight';

.room-container {
  @extend %container-style;
  position: relative;
  width: 100%;
  height: 100%;
  --has-imported-styles: 'true'; // helper var used to warn users if they haven't imported the styles package

  *[class^='lk-'],
  *[class*=' lk-'] {
    box-sizing: border-box;
  }
}
