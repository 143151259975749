@use 'sass:color';

$bg: #111;
$fg: #fff;
$danger: #f91f31;
$success: #1ff968;
$warning: #f9b11f;
$accent: #1f8cf9;

[data-theme='default'] {
  color-scheme: dark;

  --bg: #{$bg};
  --bg2: #{color.adjust($bg, $lightness: 5%)};
  --bg3: #{color.adjust($bg, $lightness: 10%)};
  --bg4: #{color.adjust($bg, $lightness: 15%)};
  --bg5: #{color.adjust($bg, $lightness: 20%)};

  --fg: #fff;
  --fg2: #{color.adjust($fg, $lightness: -4%)};
  --fg3: #{color.adjust($fg, $lightness: -8%)};
  --fg4: #{color.adjust($fg, $lightness: -12%)};
  --fg5: #{color.adjust($fg, $lightness: -16%)};

  --border-color: rgba(255, 255, 255, 0.1);

  --accent-fg: #fff;
  --accent-bg: #1f8cf9;
  --accent2: #{color.adjust($accent, $lightness: 4%)};
  --accent3: #{color.adjust($accent, $lightness: 8%)};
  --accent4: #{color.adjust($accent, $lightness: 12%)};

  --danger-fg: #fff;
  --danger: #f91f31;
  --danger2: #{color.adjust($danger, $lightness: 4%)};
  --danger3: #{color.adjust($danger, $lightness: 8%)};
  --danger4: #{color.adjust($danger, $lightness: 12%)};

  --success-fg: #fff;
  --success: #1ff968;
  --success2: #{color.adjust($success, $lightness: 4%)};
  --success3: #{color.adjust($success, $lightness: 8%)};
  --success4: #{color.adjust($success, $lightness: 12%)};

  --control-fg: var(--fg);
  --control-bg: var(--bg2);
  --control-hover-bg: var(--bg3);
  --control-active-bg: var(--bg4);
  --control-active-hover-bg: var(--bg5);

  --connection-excellent: #06db4d;
  --connection-good: #f9b11f;
  --connection-poor: #f91f31;

  --font-family: system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji';
  --font-size: 16px;
  --line-height: 1.5;
  --border-radius: 0.5rem;
  --box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.15);
  --drop-shadow: rgba(255, 255, 255, 0.2) 0px 0px 24px;

  --grid-gap: 0.5rem;
  --control-bar-height: 69px;
  --chat-header-height: 69px;
}
