/**
deprecated
*/
.audio-visualizer {
  width: 100%;
  height: 100%;
  min-height: 160px;
  background: var(--bg-control);
  aspect-ratio: 16 / 9;

  border-radius: 0.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  // gap: 0.5rem;

  & > rect {
    fill: var(--accent-bg);
    // box-shadow: 2px 2px 1px rgba(255, 255, 255, 0.4);
    // transition: transform 40ms cubic-bezier(0.42, -0.39, 0.61, 1.4);
    transition: transform 100ms cubic-bezier(0.19, 0.02, 0.09, 1);
  }

  & > path {
    stroke: var(--accent-bg);
    // box-shadow: 2px 2px 1px rgba(255, 255, 255, 0.4);
    // transition: transform 40ms cubic-bezier(0.42, -0.39, 0.61, 1.4);
    transition: 100ms cubic-bezier(0.19, 0.02, 0.09, 1);
  }
}

.audio-bar-visualizer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: var(--bg);
  gap: var(--va-bar-gap, 24px);

  & > .audio-bar {
    transform-origin: 'center';
    height: 100%;
    width: var(--va-bar-width, 12px);
    border-radius: var(--va-bar-border-radius, 32px);
    background-color: var(--va-bar-bg, rgba(136, 136, 136, 0.2));
    transition: background-color 0.25s ease-out;
    // transition: all 0.0s ease-out   // commented out as the transition duration is 0
  }

  &[data-va-state='speaking'] > .audio-bar,
  & > .audio-bar.highlighted,
  & > [data-highlighted='true'] {
    background-color: var(--fg, rgba(136, 136, 136, 1));
    transition: none;
  }

  // &[data-va-state='offline'] {
  // }

  // &[data-va-state='connecting'] {
  // }

  &[data-va-state='thinking'] {
    transition: background-color 0.15s ease-out;
  }

  // &[data-va-state='speaking'] {
  // }
}

// @keyframes connecting {
//   0% {
//     --highlight-idx: 0;
//   }

//   100% {
//     --highlight-idx: 15;
//   }
// }
